<template>
  <div class="edit-waiver-page">
    <BackTitle confirm-click @click="goBack">Edit Waiver</BackTitle>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <Guard v-else permission="waivers.edit" v-slot="{ isAvailable }">
      <WaiverForm
        :value="waiver"
        :is-submitting="isSubmitting"
        has-delete-button
        :is-disabled="!isAvailable"
        @submit="submit"
      />
    </Guard>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BackTitle from "@/components/common/BackTitle";
import WaiverForm from "@/components/waivers/WaiverForm";
import Guard from "@/components/common/Guard";

export default {
  name: "EditWaiverPage",
  components: { Guard, WaiverForm, BackTitle },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      waiver: (state) => state.waivers.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchWaiverById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchWaiverById: "waivers/fetchWaiverById",
      updateWaiver: "waivers/updateWaiver",
    }),
    goBack() {
      this.$router.push({
        name: "Waivers",
      });
    },
    async submit({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateWaiver({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-waiver-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 40px;
}
</style>
